import mainjson from '../config/main';
export default function GetData() {
        return {
        "apitype":"themes",
        "id":"theme_id",
        "subid":"theme_id",
        "subidname":"theme_name",
        "options":["edit","insert","delete"],
        "nameSingle":"theme",
        "nameMultiple":"themes",
        "filters":
            [
               
            ]
        ,
        "extraoptions":[
            {
                "name":"Unplotted points",
                "type":'custom',
                "page":"/unplottedpoints/?be_theme_id=[theme_id]&subid=1&subname=[theme_name]",
            }
        ],
        "fields":{
            "field1":{
                "name":"Name",
                "field":"theme_name",
                "type":"TextInput",
                "required":true,
                "list":true,
            }
        }
    }
}